import React from 'react';
import Layout from './layout';

export default class PageLayout extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section" style={{flex: "auto"}}>
          <div className="container">
            {this.props.children}
          </div>
        </section>
      </Layout>
    );
  }
}
