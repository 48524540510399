import React from 'react';
import PageLayout from '../layouts/pageLayout';
import { Helmet } from 'react-helmet';

export default () => (
  <PageLayout>
    <Helmet>
      <title>Iteem - about me</title>
    </Helmet>
    <div className="content">
      <h3 className="title is-1 has-text-centered">About me</h3>
      <p>
        Hi, I'm Patrick Winkler, a 29 year old software engineer from Basel, Switzerland.
      </p>
      <p>
        I've studied physics at the University of Basel and graduated with a masters degree in 2017. After that I worked
        at matchmycolor as a full stack developer, where I've already worked part time during my studies beginning in 2014.
        Since 2020 I now work at medgate.
      </p>
      <p>
        When not programming, I enjoy playing football (soccer) and piano, and like many others, gaming and traveling.
      </p>
    </div>
  </PageLayout>
);
