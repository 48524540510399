import React from 'react';
import { FaGithub, FaTwitter, FaEnvelope, FaLinkedin } from 'react-icons/fa';


export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="columns is-mobile is-centered is-size-1">
            <a className="column is-3-mobile is-2-tablet is-1-desktop" href="https://github.com/Iteem"><FaGithub/></a>
            <a className="column is-3-mobile is-2-tablet is-1-desktop" href="https://twitter.com/theIteem"><FaTwitter/></a>
            <a className="column is-3-mobile is-2-tablet is-1-desktop" href="https://www.linkedin.com/in/patrick-winkler-733287193/"><FaLinkedin/></a>
            <a className="column is-3-mobile is-2-tablet is-1-desktop" href="mailto:contact@iteem.io"><FaEnvelope/></a>
          </div>
        </div>
      </footer>
    );
  }
}