import React from 'react';
import './layout.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';

export default class Layout extends React.Component {
  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
        <Helmet>
          <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16"/>
          <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32"/>
          <title>Iteem</title>
        </Helmet>
        <Header/>
        <div style={{flex: "auto"}}>
          {this.props.children}
        </div>
        <Footer/>
      </div>
    );
  }
}
