import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

export default class Header extends React.Component {
  state =  { open: false };

  handleClick = () => {
    this.setState({open: !this.state.open})
  };

  render() {
    return (
      <header className="navbar is-fixed-top">
        <Helmet htmlAttributes={{class: "has-navbar-fixed-top"}}/>
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">Iteem</Link>
          </div>
          <span className={"navbar-burger burger" + (this.state.open ? " is-active" : "")} data-target="navMenu" onClick={this.handleClick}>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
          </span>
        </div>
        <div className={"navbar-menu" + (this.state.open ? " is-active" : "")}  id="navMenu">
          <div className="navbar-end">
            <div className="navbar-item">
              <Link to="/">Home</Link>
            </div>
            <div className="navbar-item">
              <Link to="/projects/">Projects</Link>
            </div>
            <div className="navbar-item">
              <Link to="/about/">About</Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}